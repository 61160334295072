html {
  box-sizing: border-box;
  height: 100%;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: "HalisR-Medium", "Work Sans", -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff;
  color: #212840;
}
h1,
h2,
h3 {
  font-family: "VeneerClean", sans-serif;
  line-height: 1;
  font-weight: normal;
}
#root {
  height: 100%;
}
p {
  margin: 0;
}
form {
  width: 100%;
}

a:focus,
button:focus,
input:focus,
textarea:focus {
  outline: none;
}
.ReactModal__Body--open {
  overflow: hidden;
}

.slick-prev {
  left: 25px;
  z-index: 1;
}

.slick-next {
  right: 25px;
}

.slick-dots li {
  margin: 0;
}
.slick-dots li.slick-active button:before {
  color: #fabe79;
}

.slick-dots li button:before {
  font-size: 10px;
  opacity: 1;
  color: #212840;
}

.slick-dots li button:hover {
  transition: opacity 0.3s;
  opacity: 0.5;
}

input {
  font-family: HalisR-Medium;
}

::-webkit-input-placeholder {
  font-family: HalisR-Medium;
}

::-moz-placeholder {
  font-family: HalisR-Medium;
}

:-ms-input-placeholder {
  font-family: HalisR-Medium;
}

::placeholder {
  font-family: HalisR-Medium;
}
